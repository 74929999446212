import styled from "styled-components";

export const ButtonBackground = styled.button`
  display: inline-block;
  float: right;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  margin-left: 4px;
  background-color: ${(props) =>
		props.theme.colors.neutrals.secondary.gray.divider2};
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

export const ArtistBio = styled.p`
  width: 100%;
  text-align: justify;
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
`;

export const ArtistImage = styled.div`
  width: 100%;
  padding: 8px 8px 8px 0;
  float: left;

  @media ${(props) => props.theme.device.sm} {
    max-width: 250px;
  }
`;

export const ArtistMeta = styled.div`
  width: 100%;
  padding-bottom: 16px;
`;

export const MainSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const RightSeciton = styled.div`
  flex-direction: column;
  gap: 24px;

  h2 {
    white-space: nowrap;
  }

  @media ${(props) => props.theme.device.md} {
    display: flex;
    width: 260px;
    min-width: 260px;
  }

  @media ${(props) => props.theme.device.xl} {
    display: flex;
    width: 320px;
    min-width: 320px;
  }

  @media ${(props) => props.theme.device.xxl} {
    display: flex;
    width: 360px;
    min-width: 360px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
