import { TopTracks } from "@components/Panels";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import {
	ALLOWED_VIEW_TYPES,
	HeadingWithBreadcrumb,
} from "@components/headings/HeadingWithBreadcrumb";
import { GridSlider } from "@components/sliders/GridSlider";
import { HeadingH2 } from "@components/typography/Typography.style";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { Chart } from "@models/Chart";
import { Artist } from "@models/artist";
import { Release } from "@models/release";
import { Track } from "@models/track";
import { device } from "@styles/theme";
import Image from "next/image";
import { useState } from "react";
import {
	ArtistBio,
	ArtistImage,
	ArtistMeta,
	ButtonBackground,
	MainSection,
	RightSeciton,
	Wrapper,
} from "./ArtistPage.style";

interface Props {
	artist?: Artist;
	releases?: Release[];
	tracks?: Track[];
	charts?: Chart[];
}

const ArtistPage: React.FC<Props> = ({
	artist,
	releases = [],
	tracks = [],
	charts = [],
}) => {
	const MAX_BIO_WORDS = 130;
	const totalWords = artist?.bio ? artist?.bio?.split(" ").length : 0;
	const [textCut, setTextCut] = useState(totalWords > MAX_BIO_WORDS);
	const isMd = useMediaQuery({ query: device.md });

	const rightSectionComponents = () => (
		<>
			<TopTracks
				title={<HeadingH2>Top Ten Tracks</HeadingH2>}
				location="Top Ten Tracks"
				tracks={tracks}
				isPanelList={false}
			/>
		</>
	);

	return (
		<Wrapper>
			<MainSection>
				<HeadingWithBreadcrumb
					location={`Artist - ${artist?.name}`}
					id={artist?.id || 0}
					slug={artist?.slug || ""}
					title={artist?.name || ""}
					viewType={ALLOWED_VIEW_TYPES.ARTIST}
					showSocial
					showFollow
				/>
				<ArtistMeta>
					<ArtistImage>
						<Image
							src={artist?.image?.uri || ""}
							alt={artist?.name || ""}
							width="590"
							height="435"
							sizes="100vw"
							style={{
								height: "auto",
							}}
						/>
					</ArtistImage>
					<ArtistBio>
						{artist?.bio && textCut ?
							artist?.bio.split(" ").slice(0, MAX_BIO_WORDS).join(" ") :
							artist?.bio}
						{totalWords > MAX_BIO_WORDS && (
							<ButtonBackground
								onClick={() => {
									setTextCut(!textCut);
								}}
								aria-label={textCut ? "expand bio" : "collapse bio"}
							>
								{textCut ?
										(
											<SpriteIcon id="triangle-down" width="24" height="24" />
										) :
										(
											<SpriteIcon id="triangle-up" width="24" height="24" />
										)}
							</ButtonBackground>
						)}
					</ArtistBio>
				</ArtistMeta>
				{releases.length > 0 && (
					<GridSlider
						location={`Artist - ${artist?.name} - Releases`}
						type="releases"
						title="Latest Releases"
						data={releases}
						rows={2}
					/>
				)}

				{charts.length > 0 && (
					<GridSlider
						location={`Artist - ${artist?.name} - Charts`}
						type="charts"
						title="Charts"
						data={charts}
						rows={2}
						showArtistName={true}
					/>
				)}

				{!isMd && tracks.length > 0 && (
					<RightSeciton>{rightSectionComponents()}</RightSeciton>
				)}
			</MainSection>
			{isMd && tracks.length > 0 && (
				<RightSeciton>{rightSectionComponents()}</RightSeciton>
			)}
		</Wrapper>
	);
};

export default ArtistPage;
